@tailwind base;
html {
  font-family: 'Poppins', sans-serif;
}
#select-list::-webkit-scrollbar {
  width: 8px;
}
#select-list::-webkit-scrollbar-track {
  background: rgb(229 231 235);
  border-radius: 5px;
}
#select-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #cccccc;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
@tailwind components;
@tailwind utilities;
