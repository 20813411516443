.calenderfs {
    font-size: 10px;
}
.calenderContainer::before {
    content: "";
    display: block;
    position: absolute;
    top: -8px;
    left: 50%;
    transform: rotate(45deg);
    border: 6px solid #1f1458;
    border-top-width: transparent;
    border-bottom-color: transparent;
    border-right-color: transparent;
}