.tableHead {
  background-color: #38738a;
  z-index: -1;
}

.tableCell {
  background-color: #38738a;
  padding: 10px;
}

.tableHeadCell {
  color: white;
  background-color: #38738a;
  font-size: 14px;
  padding: 10px;
  padding-left: 10px;
  font-weight: 550;
  z-index: 5;
  cursor: pointer;
}
.tablebody {
  font-size: 13px;
  color: rgb(71, 54, 97);
  padding: 8px;
  padding-left: 10px;
  font-weight: 600;
}
.tablebodyWithChecked {
  font-size: 14px;
  color: grey;
  padding: 8px;
  padding-left: 10px;
  background-color: #f2f5f5;
}
.checkboxhead {
  color: white;
}
